export function getLocaleFlag(locale: string) {
  switch (locale) {
    case 'cz':
      return 'i-j-language-mutation-flag-typeczech'
    case 'en':
      return 'i-j-language-mutation-flag-typeenglish'
    case 'de':
      return 'i-j-language-mutation-flag-typegerman'
    case 'pl':
      return 'i-j-language-mutation-flag-typepoland'

    default:
      return 'i-j-language-mutation-flag-typeczech'
  }
}

export function cleanupContentFilesForNav(contentFiles: any) {
  if (Array.isArray(contentFiles)) {
    const result = contentFiles.filter((file: any) => {
      return file.title === 'Documentation'
    })

    return result
  }
  return []
}
